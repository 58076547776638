import EventEmitter from 'eventemitter3';
import { getByLotteryShortcut } from '@/infra/items/ItemRepository';
import Archive from '@/domain/archives/index';
import type IEventEmitter from 'eventemitter3';

export function itemsGetByLotteryShortcut({
  getByLotteryShortcut,
}): IEventEmitter {
  const ee = new EventEmitter();
  getByLotteryShortcut()
    .then((res) => {
      const items = res.items.map((item) => new Archive(item));

      ee.emit('SUCCESS', {
        ...res,
        items,
      });
    })
    .catch((error) => {
      if (error.message === 'ServerError') {
        ee.emit('SERVER_ERROR', error);
        return;
      }
      ee.emit('ERROR', error);
    });
  return ee;
}

export default itemsGetByLotteryShortcut.bind(itemsGetByLotteryShortcut, {
  getByLotteryShortcut,
});
export type ResolvedData = {
  items: Archive[];
  totalCount: number;
  eventIds: number[];
};
