import { createAction, createReducer } from 'redux-act';
// types
import type { LaunchEntity } from '@/store/index';
import type { ResolvedData } from '@/app/launch/launchGetAll';
import {
  defaultState as commonDefautState,
  loadSucceedReducer,
} from '@/store/ducks/domain/mixin/itemsLoader';
import Shortcut from '@/domain/shortcuts';

const defaultState: LaunchEntity = {
  ...commonDefautState,
  shortcuts: [],
  shortCutEventIds: [],
  usingShortcuts: false,
  lotteryIds: [],
};

// simple action creators
export const launchLoadSucceeded = createAction<ResolvedData>(
  'launch/loadSucceeded'
);

export const shortcutsForLaunchLoadSucceeded = createAction<Shortcut[]>(
  'launch/loadShortcutsSucceeded'
);

export default createReducer<LaunchEntity>(
  {
    [launchLoadSucceeded.toString()]: (state: LaunchEntity, payload) => {
      return {
        ...loadSucceedReducer<LaunchEntity>(
          {
            ...state,
            shortCutEventIds: payload?.eventIds,
            usingShortcuts: payload?.usingShortcuts,
            lotteryIds: payload.lotteryIds || state.lotteryIds,
            allIds: [],
          },
          payload
        ),
        isLaunch: true,
      };
    },
    [shortcutsForLaunchLoadSucceeded.toString()]: (
      state: LaunchEntity,
      payload: Shortcut[]
    ) => {
      return {
        ...state,
        shortcuts: payload,
      };
    },
  },
  defaultState
);
