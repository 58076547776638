import Events from '@/domain/events/index';
import { getEvents } from '@/infra/events/eventRepository';
import EventEmitter from 'eventemitter3';

export function getEventsAll({ getEvents }: Ctx) {
  const ee = new EventEmitter();
  getEvents()
    .then((res) => {
      const events = res?.events?.map((e: ResolvedEventData) => new Events(e));
      ee.emit('SUCCESS', { items: events });
    })
    .catch((error: Error) => {
      if (error.message === 'ServerError') {
        ee.emit('SERVER_ERROR', error);
        return;
      }
      ee.emit('ERROR', error);
    });
  return ee;
}

export default getEventsAll.bind(getEventsAll, { getEvents });

export type ResolvedEventData = Events;
type GetEvents = typeof getEvents;
interface Ctx {
  getEvents: GetEvents;
}
