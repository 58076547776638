import ListsEvent from '@/domain/events';
import { RootState } from '@/store';
import {
  eventSelector,
  GroupedLaunch,
  sortByReleaseAtEventsSelector,
} from '@/store/selectors/domain/launch';
import { useSelector } from 'react-redux';

export const useEvents = () => {
  const events: { listEvent: ListsEvent[] } = useSelector<RootState, any>(
    eventSelector
  );

  // @ts-ignore
  const groupedEvents: GroupedLaunch = useSelector<RootState>(
    sortByReleaseAtEventsSelector
  );

  return {
    events: events?.listEvent,
    groupedEvents,
  };
};
