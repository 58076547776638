import React, { useCallback, useMemo, useEffect } from 'react';
import styles from '@/interface/ui/launch/index.module.scss';
import Items from '@/interface/ui/_shared/items/index';
import Menu from '@/interface/ui/_shared/items/menu';
import Layout from '@/interface/ui/_shared/layout/index';
import type { RootState } from '@/store/index';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useCachedScrollPosition } from '@/hooks/useCachedScrollPosition';
import { useShortcuts } from '@/hooks/useShortcuts';
import { ShortcutsBar } from '@/interface/ui/_shared/common/ShortcutsBar';
import { useLaunch } from '@/hooks/useLaunch';
import Shortcut from '@/domain/shortcuts';
import { useEvents } from '@/hooks/useEvents';
import { ShortcutsResultTitle } from '@/interface/ui/_shared/common/ShortcutsResultTitle';
import { useOS } from '@/hooks/useOS';
import { useRouter } from 'next/router';
import { join } from 'path';
import { useLotteryShortcuts } from '@/hooks/useLotteryShortcut';

interface Props {
  mode?: 'default' | 'pink';
  namespacesRequired?: string[];
}

const meta = {
  title: 'atmos LISTs | ローンチ',
  description: 'atmos LISTs | ローンチ',
};

const Launch: NextPage<Props> = ({ mode }) => {
  useCachedScrollPosition();

  const router = useRouter();
  const { isAtmosPinkApp } = useOS();

  const { groupedLaunch, fetchLaunchAsync, importLaunchData } = useLaunch();
  const { events, groupedEvents } = useEvents();

  const {
    fetchItemsAsync: fetchShortcutItemsAsync,
    shortcuts,
    activeShortcut,
    clearActivceShortcut,
    itemCount,
  } = useShortcuts('launch');
  const {
    fetchItemsAsync: fetchLotteryItemsAsync,
    groupedLotteryData,
  } = useLotteryShortcuts();
  const [eventShortcutSelected, setEventShortcutSelected] = useState(false);
  const [lotteryShortcutSelected, setLotteryShortcutSelected] = useState(false);

  const [rowNumber, setRowNumber] = useState(1);

  const { country, language, userAgent } = useSelector(
    (state: RootState) => state.app
  );

  useEffect(() => {
    if (isAtmosPinkApp && !router.pathname.includes('/pink/')) {
      const url = join('/', country, language, 'pink', 'launch');
      void router.push(url);
    }
  }, [isAtmosPinkApp]);

  const fetchShortcutItems = useCallback(
    async (shortcut: Shortcut) => {
      const data = await fetchShortcutItemsAsync(shortcut);

      if (data) {
        importLaunchData({ ...data, usingShortcuts: true });
        setEventShortcutSelected(false);
      }
    },
    [fetchShortcutItemsAsync, importLaunchData]
  );

  const clearShortcutFilter = useCallback(async () => {
    await fetchLaunchAsync();
    clearActivceShortcut();
    setLotteryShortcutSelected(false);
    setEventShortcutSelected(false);
  }, [clearActivceShortcut, fetchLaunchAsync]);

  const onPressShortcut = useCallback(
    async (shortcut: Shortcut) => {
      if (activeShortcut?.id === shortcut.id) {
        void clearShortcutFilter();
        setEventShortcutSelected(false);
        setLotteryShortcutSelected(false);
        return;
      }

      await fetchShortcutItems(shortcut);
    },
    [activeShortcut?.id, clearShortcutFilter, fetchShortcutItems]
  );

  const fetchLotteryShortcutItems = useCallback(async () => {
    const data = await fetchLotteryItemsAsync();

    if (data) {
      importLaunchData({ ...data });
    }
  }, [fetchLotteryItemsAsync, importLaunchData]);

  const onPressLotteryShortcut = useCallback(() => {
    void fetchLotteryShortcutItems();
    clearActivceShortcut();
    setEventShortcutSelected(false);
    setLotteryShortcutSelected(true);
  }, [clearActivceShortcut, fetchLotteryShortcutItems]);

  const onPressEventShortcut = useCallback(() => {
    clearActivceShortcut();
    setLotteryShortcutSelected(false);
    setEventShortcutSelected(true);
  }, [clearActivceShortcut]);

  // 検索結果のカウント表示用
  // item countにはイベントの数を含まないためここで計算
  const launchItemCount = useMemo(() => {
    let count = 0;

    Object.keys(groupedLaunch).map((groupingKey) => {
      count = count + groupedLaunch[groupingKey].length;
    });

    return Math.max(itemCount || 0, count);
  }, [groupedLaunch, itemCount]);

  const renderLaunchItem = useCallback(() => {
    const groupedData = eventShortcutSelected
      ? groupedEvents
      : lotteryShortcutSelected
      ? groupedLotteryData
      : groupedLaunch;

    return (
      <ul className={styles.wrapper}>
        {Object.keys(groupedData).map((groupingKey, index) => {
          const groupingValue: Array<{ releaseAt: string }> =
            groupedData[groupingKey];
          const releaseAt = dayjs(groupingValue[0].releaseAt).toDate();

          if (groupingKey === '発売日未定') {
            return (
              <li key={index} className={styles.itemWrapper}>
                <div className={styles.itemHeader}>
                  <span className={styles.headerValueNoDate}>COMING SOON</span>
                </div>
                <Items
                  items={groupedData[groupingKey]}
                  rowNumber={rowNumber}
                  country={country}
                  language={language}
                  userAgent={userAgent}
                  mode={mode}
                />
              </li>
            );
          }

          return (
            <li key={index} className={styles.itemWrapper}>
              <div className={styles.itemHeader}>
                <span className={styles.headerValue}>
                  {releaseAt.getMonth() + 1}
                </span>
                月
                <span className={styles.headerValue}>
                  {releaseAt.getDate()}
                </span>
                日<div></div>
              </div>
              <Items
                items={groupedData[groupingKey]}
                rowNumber={rowNumber}
                country={country}
                language={language}
                userAgent={userAgent}
                mode={mode}
              />
            </li>
          );
        })}
      </ul>
    );
  }, [
    country,
    eventShortcutSelected,
    groupedEvents,
    groupedLaunch,
    groupedLotteryData,
    language,
    lotteryShortcutSelected,
    mode,
    rowNumber,
    userAgent,
  ]);

  if (!groupedLaunch) return <Layout></Layout>;

  return (
    <>
      <Head>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta property="og:title" content={meta.title} key="title" />
      </Head>
      <Layout mode={mode}>
        <div className={styles.container}>
          <Menu
            rowNumber={rowNumber}
            setRowNumber={setRowNumber}
            props={{ country, language, mode }}
          />

          {shortcuts && (
            <ShortcutsBar
              shortcuts={shortcuts}
              onPress={onPressShortcut}
              onPressAll={clearShortcutFilter}
              onPressEvent={onPressEventShortcut}
              onPressLottery={onPressLotteryShortcut}
              selected={activeShortcut?.id}
              hasEvent={events.length > 0}
              hasLottery={true}
              eventSelected={eventShortcutSelected}
              lotterySelected={lotteryShortcutSelected}
            />
          )}
          {!!activeShortcut && <ShortcutsResultTitle count={launchItemCount} />}

          {renderLaunchItem()}
          <div className={styles.loadingWrapper}>
            <h2 className={styles.dumbFooter} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Launch;
