import { createAction, createReducer } from 'redux-act';
import { byId } from '@/infra/normalizer';

// simple action creators
export const eventLoadSucceeded = createAction<ResolvedEventData>(
  'events/loadSucceeded'
);

export default createReducer<DomainEntity<Event>>(
  {
    [eventLoadSucceeded.toString()]: (state: any, payload: any) => {
      const items = payload.items;
      return {
        ...state,
        byId: {
          ...state.byId,
          ...byId(items),
        },
        listEvent: items,
      };
    },
  },
  {
    byId: {},
    allIds: [],
    listEvent: [],
  }
);

// types
import type { DomainEntity } from '@/store/index';
import type { ResolvedEventData } from '@/app/events/launchGetEvents';
import type Event from '@/domain/events';
