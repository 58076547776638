import createConfigs from '@/config/index';
import fetch from '@/infra/fetch/index';

interface base {
  url: string;
  fetch: typeof fetch;
}

const paths = {
  getAll: '/v1/items?rds=1',
};

function getAllCtx({ url, fetch }: base) {
  return fetch(url)
    .then(async (v) => {
      const res = await v.json();
      return { items: res.items, scrollId: res.scroll_id };
    })
    .catch((err) => {
      throw err;
    });
}

export const getAll = getAllCtx.bind(getAllCtx, {
  fetch,
  url: new URL(paths.getAll, createConfigs().apiServer.origin).href,
});
