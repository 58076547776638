import { useErrorHandler } from '@/hooks/useErrorHandler';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { executeUsercase } from '@/app/executeUsercase';
import itemsGetByLotteryShortcut, {
  ResolvedData,
} from '@/app/items/itemsGetByLotteryShortcut';
import pinkItemsGetByLotteryShortcut from '@/app/pink/items/itemsGetByLotteryShortcut';
import {
  GroupedLaunch,
  launchSelector,
  lotteryFilteredSelector,
} from '@/store/selectors/domain/launch';

const buildLotteryShortcutHooks = (usecase: any) => () => {
  const groupedLotteryData: GroupedLaunch = useSelector(
    lotteryFilteredSelector
  );
  const { lotteryIds } = useSelector(launchSelector) || {};

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { throwError } = useErrorHandler();

  const fetchItemsAsync = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const data = await executeUsercase<ResolvedData>(usecase, {});

      if (data) {
        const lotteryIds = data.items.map((i: any) => i.id);
        return { ...data, lotteryIds };
      }
    } catch (error) {
      throwError(error, 'LISTSの取得に失敗しました');
    } finally {
      setIsLoading(false);
    }
  }, [throwError]);

  const hasLottery = useMemo(() => {
    return !!lotteryIds?.length;
  }, [lotteryIds?.length]);

  return {
    isLoading,
    isError,
    fetchItemsAsync,
    hasLottery,
    groupedLotteryData,
  };
};

export const useLotteryShortcuts = buildLotteryShortcutHooks(
  itemsGetByLotteryShortcut
);
export const usePinkLotteryShortcuts = buildLotteryShortcutHooks(
  pinkItemsGetByLotteryShortcut
);
