import dayjs from 'dayjs';
import { createSelector } from 'reselect';

// @see:https://github.com/reduxjs/reselect#reselect
export const launchSelector: Selector<RootState, LaunchEntity | undefined> = (
  state: RootState
) => state.domain.launch;

export const eventSelector: Selector<
  RootState,
  DomainEntity<Event> | undefined
> = (state: RootState) => state.domain.events;

export const sortByReleaseAtSelector = createSelector(
  [launchSelector, eventSelector],
  (launchList, events): any => {
    const grouped = {};
    if (launchList === undefined) return null;

    let listEvents = events?.listEvent;
    if (launchList.usingShortcuts && listEvents) {
      // ショートカットの場合はイベントは指定の者のみでfilter

      listEvents =
        launchList.shortCutEventIds!.length > 0
          ? events?.listEvent!.filter((e: Event) =>
              // @ts-ignore launchListは事前にnullチェック済み
              launchList.shortCutEventIds.includes(e.id)
            )
          : [];
    }

    if (listEvents) {
      for (const eventItem of listEvents) {
        const key = dayjs(eventItem.event_at).format('YYYYMMDD');
        const byReleaseAt: any[] = grouped[key] ? grouped[key] : [];
        byReleaseAt.push(eventItem);
        grouped[key] = byReleaseAt;
      }
    }

    // group by releaseAt order by releaseAt desc, id desc
    for (const id of launchList.allIds) {
      const launch: Launch = launchList.byId[`${id}`];

      const key =
        launch.releaseAtDisplay || dayjs(launch.releaseAt).format('YYYYMMDD');

      const launchedObj = Object.assign(launch, {
        isLaunch: launchList.isLaunch,
      });

      const byReleaseAt: any[] = grouped[key] ? grouped[key] : [];
      byReleaseAt.push(launchedObj);
      grouped[key] = byReleaseAt;
    }

    return grouped;
  }
);

export const sortByReleaseAtEventsSelector = createSelector(
  [eventSelector],
  (events): any => {
    const grouped = {};
    const listEvents = events?.listEvent;

    if (listEvents) {
      for (const eventItem of listEvents) {
        const key = dayjs(eventItem.event_at).format('YYYYMMDD');
        const byReleaseAt: any[] = grouped[key] ? grouped[key] : [];
        byReleaseAt.push(eventItem);
        grouped[key] = byReleaseAt;
      }
    }

    return grouped;
  }
);

export const lotteryFilteredSelector = createSelector(
  [launchSelector],
  (launchList): any => {
    const grouped = {};
    if (launchList === undefined) return null;

    // group by releaseAt order by releaseAt desc, id desc
    for (const id of launchList.allIds) {
      if (launchList?.lotteryIds?.includes(id)) {
        const launch: Launch = launchList.byId[`${id}`];

        const key =
          launch.releaseAtDisplay || dayjs(launch.releaseAt).format('YYYYMMDD');

        const launchedObj = Object.assign(launch, {
          isLaunch: launchList.isLaunch,
        });

        const byReleaseAt: any[] = grouped[key] ? grouped[key] : [];
        byReleaseAt.push(launchedObj);
        grouped[key] = byReleaseAt;
      }
    }

    return grouped;
  }
);

// types
export type GroupedLaunch = {
  [key: string]: [];
};

import type { Selector } from 'reselect';
import type { RootState, DomainEntity, LaunchEntity } from '@/store/index';
import type Launch from '@/domain/launch/index';
import type Event from '@/domain/events/index';
