import { RootState } from '@/store';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { GroupedLaunch } from '@/store/selectors/domain/launch';
import { sortByReleaseAtSelector } from '@/store/selectors/domain/launch';
import type { ResolvedEventData } from '@/app/events/launchGetEvents';
import launchGetEvents from '@/app/events/launchGetEvents';
import { executeUsercase } from '@/app/executeUsercase';
import launchGetAll from '@/app/launch/launchGetAll';
import type { ResolvedData } from '@/app/launch/launchGetAll';
import { launchLoadSucceeded } from '@/store/ducks/domain/launch';
import { eventLoadSucceeded } from '@/store/ducks/domain/events';

export const useLaunch = () => {
  const groupedLaunch: GroupedLaunch = useSelector<RootState, GroupedLaunch>(
    sortByReleaseAtSelector
  );

  const dispatch = useDispatch();

  const fetchLaunchAsync = useCallback(async () => {
    const [launchData, eventsData] = await Promise.all([
      executeUsercase<ResolvedData>(launchGetAll, {}),
      executeUsercase<ResolvedEventData>(launchGetEvents, {}),
    ]);

    dispatch(launchLoadSucceeded(launchData));
    dispatch(eventLoadSucceeded(eventsData));
  }, [dispatch]);

  // apiからのデータを処理してlaunchに追加
  const importLaunchData = useCallback(
    (data: any) => {
      dispatch(
        launchLoadSucceeded({
          ...data,
        })
      );
    },
    [dispatch]
  );

  return {
    fetchLaunchAsync,
    groupedLaunch,
    importLaunchData,
  };
};
