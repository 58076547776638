// types
import type { ResolvedData } from '@/app/launch/launchGetAll';
import { executeUsercase } from '@/app/executeUsercase';
import launchGetAll from '@/app/launch/launchGetAll';
import Launch from '@/interface/ui/launch/index';
import { appFetchSucceeded } from '@/store/ducks/app/index';
import { appUserAgentDetermined } from '@/store/ducks/app/index';
import {
  launchLoadSucceeded,
  shortcutsForLaunchLoadSucceeded,
} from '@/store/ducks/domain/launch';
import { eventLoadSucceeded } from '@/store/ducks/domain/events';
import type { RootState, RootStore } from '@/store/index';
import type { ResolvedEventData } from '@/app/events/launchGetEvents';
import launchGetEvents from '@/app/events/launchGetEvents';
import { NextPageContext } from 'next';
import { shorcutsForLaunchGetAll } from '@/app/shortcuts/shortcutsGetAll';
import Shortcut from '@/domain/shortcuts';

async function fetchAndStore(store: RootStore, path?: string) {
  const [launchData, eventsData, shortcutData] = await Promise.all([
    executeUsercase<ResolvedData>(launchGetAll, {}),
    executeUsercase<ResolvedEventData>(launchGetEvents, {}),
    executeUsercase<{ shortcuts: Shortcut[] }>(shorcutsForLaunchGetAll, {}),
  ]);

  store.dispatch(launchLoadSucceeded(launchData));
  store.dispatch(eventLoadSucceeded(eventsData));
  store.dispatch(shortcutsForLaunchLoadSucceeded(shortcutData.shortcuts));
  store.dispatch(appFetchSucceeded([path || '']));
}

Launch.getInitialProps = async ({
  req,
  store,
  asPath,
}: NextPageContext & { store: RootStore }) => {
  const namespacesRequired = ['common'];
  const isClient = req === undefined;

  if (isClient) {
    const { app }: RootState = store.getState();
    const { fetchedPaths, userAgent } = app;
    (asPath && fetchedPaths.includes(asPath)) ||
      (await fetchAndStore(store, asPath));

    if (!userAgent) {
      const userAgent = navigator.userAgent;
      store.dispatch(appUserAgentDetermined(userAgent));
    }

    return { mode: 'default' };
  }

  await fetchAndStore(store, asPath);

  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent;
  store.dispatch(appUserAgentDetermined(userAgent));

  return {
    namespacesRequired,
    mode: 'default',
  };
};

export default Launch;
