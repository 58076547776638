import Launch from '@/domain/launch/index';
import { getAll } from '@/infra/launch/launchRepository';
import EventEmitter from 'eventemitter3';

export function launchGetAll({ getAll }) {
  const ee = new EventEmitter();
  getAll()
    .then((res) => {
      const data: ResolvedData = res.items.map((chunk) => new Launch(chunk));
      ee.emit('SUCCESS', { items: data, scrollId: res.scrollId });
    })
    .catch((error) => {
      if (error.message === 'ServerError') {
        ee.emit('SERVER_ERROR', error);
        return;
      }
      ee.emit('ERROR', error);
    });
  return ee;
}

export default launchGetAll.bind(launchGetAll, { getAll });

// types
export type ResolvedData = Launch[];
